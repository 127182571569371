<template>
  <div>
    <div id="section-content" class="container container-fluid">
      <div class="bar-back">
        <router-link to="/member/main"><i class="fas fa-chevron-left"></i> หน้าหลัก</router-link>
      </div>
      <div class="p-2 w-100 bg-light main-content align-self-stretch" style="min-height: calc((100vh - 140px) - 50px)">
        <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-1 pb-0">
          <div class="lotto-title">
            <h4><i class="fas fa-file-invoice-dollar"></i> รายงานการเงิน</h4>
          </div>
        </div>
        <ul class="nav nav-tabs nav-justified" id="menucredit" role="tablist">
          <li class="nav-item" @click="getListToday">
            <a
              class="nav-link active"
              href="#tab1content"
              data-toggle="tab"
              id="tab1contentt"
              role="tab"
              aria-controls="home"
              aria-selected="true"
              ><i class="fas fa-calendar-week"></i> วันนี้</a
            >
          </li>
          <li class="nav-item" @click="getListHistory">
            <a
              class="nav-link"
              data-toggle="tab"
              href="#tab2content"
              role="tab"
              aria-controls="home"
              aria-selected="true"
              id="tab2contentt"
              ><i class="fas fa-history"></i> ก่อนหน้า</a
            >
          </li>
        </ul>

        <div class="tab-content" id="myTabContent">
          <div id="tab1content" class="tab-pane fade active show" role="tabpanel" aria-labelledby="tab1contentt">
            <div class="mb-5 py-1 bg-light rounded col-lotto">
              <div class="accordion bg-transparent" id="accordionCredit">
                <div v-for="(item, index) in listTransaction" v-bind:key="index">
                  <div
                    class="
                      bg-white
                      border border-bottom-0
                      rounded-top
                      p-2
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                    v-on:click="item.isHide = !item.isHide"
                  >
                    <div class="d-flex flex-column align-items-start text-secondary">
                      <div class="align-middle">
                        <span
                          class="badge font-weight-light w-auto"
                          :class="[IsPossitiveTracn(item.status_code) ? 'badge-warning' : 'badge-success']"
                          >{{ getStatus(item.status_code) }}</span
                        >
                      </div>
                      <small
                        >{{ IsUserProcess(item.status_code) ? thisUser : 'ระบบ' }}
                        <i class="fas fa-angle-right"></i>
                        {{ IsUserProcess(item.status_code) ? 'ระบบ' : '' }}</small
                      >
                    </div>
                    <div class="d-flex flex-column align-items-end">
                      <h4
                        class="mb-0 d-inline"
                        :class="[IsPossitiveTracn(item.status_code) ? 'text-danger' : 'text-success']"
                      >
                        {{ IsPossitiveTracn(item.status_code) ? '-' : '+' }}
                        <span class="thb">฿ {{ numberWithCommas(item.amount) }}</span>
                      </h4>
                      <small class="text-secondary"><i class="far fa-clock"></i> {{ item.dateTimeTransaction }}</small>
                    </div>
                  </div>
                  <div v-if="item.isHide" class="collapse show" aria-labelledby="hlist1" data-parent="#accordionCredit" style="">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div class="align-middle">
                        <span class="badge badge-secondary font-weight-light">เลขที่รายการ #{{ item.transID }}</span>
                            </div>
                            <div class="align-middle">
                                <small>คงเหลือ</small> <span class="thb text-info">฿ {{ numberWithCommas(item.credit) }}</span>
                            </div>
                        </div>
                        <small class="text-secondary">หมายเหตุ: {{ item.title }} </small>
                        <small></small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="tab2content" class="tab-pane fade" role="tabpanel" aria-labelledby="tab2contentt">
            <div class="mb-5 py-1 bg-light rounded col-lotto">
              <div class="accordion bg-transparent" id="accordionCredithistory">
                <div v-for="(item, index) in listTranHistory" v-bind:key="index">
                  <div
                    class="
                      bg-white
                      border border-bottom-0
                      rounded-top
                      p-2
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                    v-on:click="item.isHide = !item.isHide"
                  >
                    <div class="d-flex flex-column align-items-start text-secondary">
                      <div class="align-middle">
                        <span
                          class="badge font-weight-light w-auto"
                          :class="[IsPossitiveTracn(item.status_code) ? 'badge-warning' : 'badge-success']"
                          >{{ getStatus(item.status_code) }}</span
                        >
                      </div>
                      <small
                        >{{ IsUserProcess(item.status_code) ? thisUser : 'ระบบ' }}
                        <i class="fas fa-angle-right"></i>
                        {{ IsUserProcess(item.status_code) ? 'ระบบ' : '' }}</small
                      >
                    </div>
                    <div class="d-flex flex-column align-items-end">
                      <h4
                        class="mb-0 d-inline"
                        :class="[IsPossitiveTracn(item.status_code) ? 'text-danger' : 'text-success']"
                      >
                        {{ IsPossitiveTracn(item.status_code) ? '-' : '+' }}
                        <span class="thb">฿ {{ numberWithCommas(item.amount) }}</span>
                      </h4>
                      <small class="text-secondary"><i class="far fa-clock"></i> {{ item.dateTimeTransaction }}</small>
                    </div>
                  </div>
                  <div v-if="item.isHide" class="collapse show" aria-labelledby="hlist1" data-parent="#accordionCredit" style="">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div class="align-middle">
                        <span class="badge badge-secondary font-weight-light">เลขที่รายการ #{{ item.transID }}</span>
                            </div>
                            <div class="align-middle">
                                <small>คงเหลือ</small> <span class="thb text-info">฿ {{ numberWithCommas(item.credit) }}</span>
                            </div>
                        </div>
                        <small class="text-secondary">หมายเหตุ: {{ item.title }} </small>
                        <small></small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { listTodayTranService, listTranHistoryService } from '@/services'
import { mapActions } from 'vuex'
import { STATUS_SUCCESS } from '@/constants'
export default {
  data () {
    return {
      listTransaction: [],
      listTranHistory: [],
      thisUser: 'ไม่พบ',
      isHidden: false
    }
  },
  async created () {
    await this.getListToday()
  },
  methods: {
    ...mapActions(['setLoading', 'openInfoPopup']),
    numberWithCommas (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    getStatus (type) {
      let typeDesc = ''
      switch (Number(type)) {
        case 0:
          typeDesc = 'แทงหวย'
          break
        case 1:
          typeDesc = 'ชนะเดิมพัน'
          break
        case 2:
          typeDesc = 'ยกเลิกโพย'
          break
        case 3:
          typeDesc = 'ถอนเงิน'
          break
        case 4:
          typeDesc = 'ฝากเงิน'
          break
        case 5:
          typeDesc = 'โบนัสเงิน AF'
          break
        case 6:
          typeDesc = 'ถอนเงิน AF'
          break
        case 7:
          typeDesc = 'ยกเลิกรอบหวยและเรียกคืนรายได้'
          break
        case 8:
          typeDesc = 'ถอนเครดิตตรง'
          break
        case 9:
          typeDesc = 'เติมเครดิตตรง'
          break
        case 10:
          typeDesc = 'ยกเลิกถอนเงิน'
          break
        case 11:
          typeDesc = 'ฝากเงินจากระบบอัตโนมัติ'
          break
        case 12:
          typeDesc = 'โบนัสยิงเลข'
          break
        case 13:
          typeDesc = 'หักเติมตรงให้ลูกค้า'
          break
        case 14:
          typeDesc = 'เติมเงินคาสิโน'
          break
        case 15:
          typeDesc = 'ถอนเงินคาสิโน'
          break
        case 16:
          typeDesc = 'เติมตรงคาสิโน'
          break
        case 17:
          typeDesc = 'ถอนตรงคาสิโน'
          break
        default:
          typeDesc = 'ไม่พบประเภทธุรกรรม'
          break
      }
      return typeDesc
    },
    IsPossitiveTracn (type) {
      let typeDesc = false
      if (Number(type) === 0 || Number(type) === 3 ||
      Number(type) === 8 || Number(type) === 14 || Number(type) === 13 || Number(type) === 17) {
        typeDesc = true
      }
      return typeDesc
    },
    IsUserProcess (type) {
      let typeDesc = false
      if (Number(type) === 0 || Number(type) === 2 || Number(type) === 3 ||
      Number(type) === 4 || Number(type) === 6) {
        typeDesc = true
      }
      return typeDesc
    },
    IsShow (event) {
      event = true
      // console.log(event)
    },
    async getListToday () {
      this.setLoading(true)
      try {
        const resp = await listTodayTranService()
        if (resp.status_code === STATUS_SUCCESS) {
          // this.listTransaction = resp.data.listTransaction
          this.thisUser = resp.data.username
          const data = resp.data.listTransaction.map(e => {
            return {
              ...e,
              isHide: false
            }
          })
          this.listTransaction = data
        }
      } catch (error) {}
      window.scrollTo(0, 0)
      this.setLoading(false)
    },
    async getListHistory () {
      this.setLoading(true)
      try {
        const resp = await listTranHistoryService()
        if (resp.status_code === STATUS_SUCCESS) {
          // this.listTranHistory = resp.data.listTransaction
          this.thisUser = resp.data.username
          const data = resp.data.listTransaction.map(e => {
            return {
              ...e,
              isHide: false
            }
          })
          this.listTranHistory = data
        }
      } catch (error) {}
      window.scrollTo(0, 0)
      this.setLoading(false)
    }
  }
}
</script>
